import styled from '@emotion/styled';
import { Badge, theme, Text, Grid } from '@onehope/design-system-v2';

export const Container = styled.div`
  display: flex;
`;

export const GridContainer = styled(Grid)`
  margin: 8px 0;
  @media all and (min-width: 768px) {
    margin: 24px 0;
  }
`;

export const NotificationBadge = styled(Badge)`
  .MuiBadge-anchorOriginTopRightRectangular {
    transform: scale(1) translate(-30%, 50%);
    background-color: #f4564e;
  }
  .MuiBadge-dot {
    height: 8px;
    min-width: 8px;
    border: 2px solid white;
  }
`;

export const Notification = styled(Text)`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-family: ${theme.bodyFontFamily};
  /* identical to box height, or 150% */
  color: ${theme.palette.primary.main};
`;

export const TextContainer = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  //margin-bottom: 8px;
  //:last-of-type {
  //  padding-bottom: 32px;
  //}
  @media all and (min-width: 768px) {
    margin-bottom: 0;
    /* flex-direction: row; */
  }
`;

export const Link = styled.span`
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-family: ${theme.bodyFontFamily};
  /* identical to box height, or 150% */
  color: ${theme.palette.forestGreen.main};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  @media all and (min-width: 960px) {
    margin: 0 8px;
  }
`;

export const Spacer = styled.div`
  padding-right: 5px;
`;

export const ClosedEvent = styled.div`
  font-style: italic;
  font-weight: 420;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin-top: 8px;
  font-family: ${theme.bodyFontFamily};
  /* identical to box height, or 150% */
  color: ${theme.palette.greyDark.main};
`;
