import { styles as s } from '@onehope/design-system';
import { Text, theme } from '@onehope/design-system-v2';

import styled from '@emotion/styled';
import { Key, Value } from '../../../../Event/TabContent/index.styles';

const { cssConstants } = s;

interface WideKeyProps {
  width: number;
}

export const WideKey = styled(Key)<WideKeyProps>`
  min-width: 160px;
  margin-right: 8px;
  @media all and (min-width: 768px) {
    display: flex;
    min-width: ${(props) => (props.width ? `${props.width}px` : '200px')};
  }
`;

export const Title = styled(Text)`
  margin-right: 8px;
`;

export const Link = styled(Value)`
  font-weight: bold;
  word-break: break-all;
  text-decoration: underline;
  cursor: pointer;
`;

export const LinkV2 = styled(Text)`
  word-break: break-all;
  cursor: pointer;
  color: ${theme.palette.forestGreen.main};
  font-weight: 420;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  letter-spacing: 0.01em;
  &:hover {
    text-decoration: underline;
  }
`;

export const ChangeHost = styled.div`
  font-size: 11px;
  font-weight: bold;
  line-height: 1.45;
  text-decoration: underline;
  cursor: pointer;
  color: ${cssConstants.primaryColor};
  margin-top: 4px;
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px !important;
`;

export const AddressValue = styled.div`
  font-size: 14px;
  line-height: 19px;
  word-break: break-word;
  margin-right: 0;
  text-align: right;
  width: 120px;
  @media all and (min-width: 768px) {
    text-align: left;
    width: 432px;
    margin-left: 0;
  }
`;

export const ExpirationDateValue = styled.div`
  font-size: 14px;
  line-height: 19px;
  word-break: break-word;
  margin-right: 0;
  text-align: right;
  color: #88898a;
  font-weight: 300;
  margin-top: 5px;
  width: 147px;
  margin-left: -23px;
  @media all and (min-width: 768px) {
    text-align: left;
    width: 432px;
    margin-left: 0;
  }
`;
