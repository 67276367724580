import React, { useEffect, useState, memo } from 'react';
import { FastField, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';
import get from 'lodash/get';
import { useMutation, useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { styles } from '@onehope/design-system';
import {
  MuiButton,
  MuiInputAutosize,
  MuiToolTip,
} from '@onehope/design-system-ohw';

import {
  Grid,
  Icons,
  useTheme,
  useMediaQuery,
  MenuItem,
  Dialog as DialogRoot,
  FormControlLabel,
  DatePickerContainer,
  makeStyles,
  Checkbox,
  Text,
  Switch,
  TEXT_DS,
  Box,
  ButtonBase,
  ThemeType,
  theme,
  Input as MuiInput,
  ButtonV2,
} from '@onehope/design-system-v2';

import { ContactType } from '../../ContactsV3/ContactsContext';

/* Components */
import AutoComplete from './AutoComplete';
import {
  RadioButton,
  RadioButtonGroup,
  RadioChunkButton,
} from '../../../common/RadioButton';
import SaveButtons from '../../Dialog/EventCreateDialog/SaveButtons';
import FooterButtons from '../../Dialog/EventCreateDialog/FooterButtons';
import AddCustomerForm from '../AddCustomerForm/AddCustomerForm';

/* Mutations & Queries */
import AddressAutoCompleteAddressMutation from '../../../mutations/Event/AddressFormAddressAutoCompleteMutation';
import AddressFormGooglePlacesZipMutation from '../../../mutations/Event/AddressFormGooglePlacesZipMutation';
import GET_CONTACTS_QUERY from '../../../queries/ContactsV2';

/* Types */
import { CEDash_EventDetailsPage_viewer_event as EventType } from '../../../queries/generatedTypes/CEDash_EventDetailsPage';
import { MyFormValues } from '../../Event/TabContent/Details/FormTypes';

/* Helpers & Functions */
import { allowedStates, statesAbbrev } from '../../../utils/statesWeCanShipTo';
import { formatAddress } from '../../Event/TabContent/Charity/helpers';
import { focusErrors } from './submitHelpers';
import { timeZones } from './timeZones';
import TimeMask from './TimeMask';
import {
  EventTypeEnum,
  EventHostingTypeEnum,
  EventVersionTypeEnum,
} from '../../EventsV2/eventTypes';

import { ToggleLabel } from '../../Event/TabContent/index.styles';
import {
  BoldText,
  SelectInput,
  DefaultOption,
  ArrowDropDown,
  MiniSelect,
  MenuProps,
  Col,
  Row,
  SelectContainer,
} from './index.styles';
import { CardInfo, CardLine, CardTitle } from '../AddCustomerForm/index.styles';
// import EditImage from './EditImage';
import NonProfitSelection from '../NonProfitV2Selection';
import {
  Details,
  DonationContainer,
  Title,
  LocationContainer,
} from '../CharityDetailsForm/index.styles';
import CurrencyMask from '../CharityDetailsForm/CurrencyMask';
import { EventStatusDictionary } from '../../../utils/enums';
import useToggle from '../../../utils/useToggleHook';

const { cssConstants } = styles;
const { AddCircleOutlinedIcon } = Icons;

export interface Address {
  addressId: string;
  addressLineOne: string;
  addressLineTwo?: string;
  city: string;
  country: string;
  id: string;
  placeId: string;
  state: string;
  zip?: string;
}

interface CustomerData {
  fullName: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  email: string;
  phone: string;
  accountId: string;
  contactId: string;
  dateOfBirth: string;
}

interface EventDetailsFormProps {
  handlePreviousStepChange: any;
  editing: boolean;
  event: EventType;
}

type FormProps = {
  setIsNotEditing: () => void;
  mutationLoading: boolean;
};

const useStyles = makeStyles((theme: ThemeType) => ({
  datePickerPaperContainer: {
    minWidth: '342px',
  },
  dateStyles: ({ canUpdateEventDates }: { canUpdateEventDates: boolean }) => ({
    fontFamily: theme.bodyFontFamily,
    fontSize: '14px',
    lineHeight: '21px',
    color: canUpdateEventDates
      ? theme.palette.primary.main
      : theme.palette.greyDark.main,
    letterSpacing: '0.01em',
  }),
  formControlStyles: {
    marginRight: 0,
  },
  labelStyles: {
    margin: '24px 0 8px 0',
  },
  genericLabelStyles: {
    margin: '0 0 8px 0',
  },
  tinyTextStyles: {
    padding: '12px 16px 12px 0px',
  },
  eventsSupportContainerStyles: {
    paddingTop: 40,
    marginBottom: 20,
  },
  containerFullWithStyles: {
    width: '100%',
  },
  formContainerStyles: {
    paddingBottom: 60,
  },
  toolTipStyles: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const NonProfitContainer = styled.div`
  margin-top: 40px;
`;

const NewCustomerDialog = styled(DialogRoot)`
  &.MuiDialog-root {
  }
`;

export const CustomerCard = styled.div`
  max-height: 200px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px #c2c6c9;
  background-color: ${cssConstants.neutralBackgroundColor};
`;

export const UpdateButton = styled(Text)`
  cursor: pointer;
`;

const InputContainer = styled.div`
  padding-top: 24px;
`;

const LogisticsContainer = styled.div`
  width: 100%;
`;

const EventTitleContainer = styled.div`
  margin: 40px 0 0 0;
`;

const EventVirtualLinkContainer = styled.div`
  margin: 40px 0 0 0;
`;

const ShortInput = styled(MuiInput)`
  && {
    font-family: ${theme.bodyFontFamily};
    width: 106px;
    margin-bottom: 25px;
    @media all and (min-width: 768px) {
      width: 216px;
    }
  }
`;

const LongInput = styled(MuiInput)`
  && {
    font-family: ${theme.bodyFontFamily};
    max-width: 460px;
    margin-bottom: 25px;
  }
`;

const RegularInput = styled(MuiInput)`
  && {
    max-width: 460px;
  }
`;

export const MedInput = styled(MuiInput)`
  && {
    display: flex;
    flex-direction: column;
    width: 244px;
    margin-bottom: 25px;
  }
`;

export const SearchLink = styled.div`
  max-width: 460px;
  height: 18px;
  font-family: ${cssConstants.font};
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  color: ${cssConstants.primaryColor};
  text-decoration: underline;
  margin-top: -15px;
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-end;
  cursor: pointer;
`;

const OptionLabel = styled.div`
  padding-left: 16px;
`;

const NewCustomerButton = styled('div')(() => ({
  width: '100%',
  height: '100px',
  paddingTop: '8px',
  marginBottom: '40px',
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

const CustomerTextContainer = styled('div')(() => ({
  width: '432px',
  height: '88px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const HorizontalLine = styled('div')(() => ({
  width: '100%',
  height: '1px',
  backgroundColor: '#F2F2F2',
}));

const HorizontalLineContainer = styled(Grid)(() => ({
  minWidth: '45%',
}));

const StyledAddNewButton = styled(ButtonV2)(() => ({
  border: '1px dashed',
  borderRadius: '4px',
  marginTop: '20px',
  height: '100px',
  fontSize: '12px',
  fontWeight: 'bold',
  textAlign: 'center',
  cursor: 'pointer',
}));

const OrText = styled(Text)(() => ({
  textAlign: 'center',
}));

const formatDisplayDate = (date: DateTime) => {
  if (!date) return '';
  if (!date.c) return date;
  const {
    c: { month, day, year },
  } = date;
  const formattedDate = `${month}/${day.toString().padStart(2, 0)}/${year}`;
  return formattedDate.padStart(10, 0);
};

export const formatPhone = (value: string) => {
  if (!value) return null;
  let i = 0;
  const number = value.replace(/[- )(+]/g, '');
  const pattern = number.length === 10 ? '(###) ###-####' : '# (###) ###-####';
  return pattern.replace(/#/g, (_) => number[i++]);
};

const formatCustomers = (data: any) => {
  return (
    get(data, 'viewer.v2.contactsV2.contacts.data') &&
    get(data, 'viewer.v2.contactsV2.contacts.data').map(
      (customer: ContactType) =>
        customer
          ? {
              organizationName: customer?.organizationName || '',
              fullName: `${customer?.firstName} ${customer?.lastName}`,
              firstName: customer?.firstName || '',
              lastName: customer?.lastName || '',
              email: customer?.emailAddress,
              phone: customer?.phoneNumber,
              accountId: customer?.accountId,
              contactId: customer?.contactId,
            }
          : '',
    )
  );
};

export const getManualAddress = (
  editing: boolean,
  type: string,
  eventId: string,
) => {
  if (editing && localStorage.getItem(`${type}${eventId}`)) {
    return localStorage.getItem(`${type}${eventId}`) === 'true';
  } else {
    if (localStorage.getItem(`${type}`)) {
      return localStorage.getItem(`${type}`) === 'true';
    }
  }
  return false;
};

const EventDetailsForm = ({
  values,
  errors,
  touched,
  event,
  handleChange,
  handleReset,
  handleSubmit,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  setIsNotEditing,
  mutationLoading,
  handlePreviousStepChange,
  editing,
  donation,
}: FormProps & FormikProps<MyFormValues> & EventDetailsFormProps) => {
  const {
    trinityPartyType,
    hostFullName,
    hostFirstName,
    hostContactId,
    hostLastName,
    hostDateOfBirth,
    hostEmail,
    hostPhone,
    hostAccountId,
    eventDate,
    hostType,
    hostCustomerType,
    displayAddress,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zip,
    eventTime,
    eventTimeLocale,
    timeZone,
    title,
    eventLocation,
    showSupporters,
    eventType,
    nonProfitId: nonProfitV2Id,
    charityDonationGoal,
    charityQuote,
    charityType,
    showDonationGoal,
    preApprovedNonProfit,
    lobMemo,
    canUpdateEventDates,
    eventVirtualLink,
    skipNonProfit: skipNpo,
    nonProfitEditId,
  } = values;

  const {
    datePickerPaperContainer,
    dateStyles,
    labelStyles,
    genericLabelStyles,
    formControlStyles,
    tinyTextStyles,
    toolTipStyles,
    formContainerStyles,
    eventsSupportContainerStyles,
  } = useStyles({ canUpdateEventDates });

  const {
    value: showSetEndDate,
    setTrue: setShowSetEndDate,
    setFalse: setHideSetEndDate,
  } = useToggle(!editing);

  const currentDate = DateTime.local().setZone(timeZone);

  // avoid using for loop for useEffect since hooks have to render in same order (throwing errors)
  useEffect(() => {
    localStorage.setItem(
      'trinityPartyType',
      trinityPartyType ? trinityPartyType : '',
    );
    setFieldTouched('trinityPartyType', true, false);
  }, [trinityPartyType, setFieldTouched]);

  useEffect(() => {
    localStorage.setItem('hostFullName', hostFullName ? hostFullName : '');
  }, [hostFullName]);

  useEffect(() => {
    localStorage.setItem(
      'eventVirtualLink',
      eventVirtualLink ? eventVirtualLink : '',
    );
  }, [eventVirtualLink]);

  useEffect(() => {
    localStorage.setItem('eventType', eventType ? eventType : '');
    if (!editing) {
      localStorage.setItem('eventDate', '');
      setFieldValue('eventDate', '');
      setFieldTouched('eventType', true, false);
    }
    if (eventType === EventTypeEnum.Fundraiser) {
      localStorage.setItem('trinityPartyType', 'EPARTY');
    }
  }, [editing, eventType, setFieldTouched, setFieldValue]);

  useEffect(() => {
    localStorage.setItem('hostAccountId', hostAccountId ? hostAccountId : '');
  }, [hostAccountId]);

  useEffect(() => {
    localStorage.setItem('hostEmail', hostEmail ? hostEmail : '');
  }, [hostEmail]);

  useEffect(() => {
    localStorage.setItem('hostPhone', hostPhone ? hostPhone : '');
  }, [hostPhone]);

  useEffect(() => {
    localStorage.setItem('eventDate', eventDate ? eventDate : '');
  }, [eventDate]);

  useEffect(() => {
    localStorage.setItem('hostType', hostType ? hostType : '');
    setFieldTouched('hostType', true, false);
  }, [hostType, setFieldTouched]);

  useEffect(() => {
    localStorage.setItem(
      'hostCustomerType',
      hostCustomerType ? hostCustomerType : '',
    );
  }, [hostCustomerType]);

  useEffect(() => {
    localStorage.setItem(
      'displayAddress',
      displayAddress ? displayAddress : '',
    );
  }, [displayAddress]);

  useEffect(() => {
    localStorage.setItem(
      'addressLineOne',
      addressLineOne ? addressLineOne : '',
    );
  }, [addressLineOne]);

  useEffect(() => {
    localStorage.setItem(
      'addressLineTwo',
      addressLineTwo ? addressLineTwo : '',
    );
  }, [addressLineTwo]);

  useEffect(() => {
    localStorage.setItem('city', city ? city : '');
  }, [city]);

  useEffect(() => {
    localStorage.setItem('state', state ? state : '');
  }, [state]);

  useEffect(() => {
    localStorage.setItem('zip', zip ? zip : '');
  }, [zip]);

  useEffect(() => {
    localStorage.setItem('title', title ? title : '');
  }, [title]);

  useEffect(() => {
    localStorage.setItem('eventTime', eventTime ? eventTime : '');
  }, [eventTime]);

  useEffect(() => {
    localStorage.setItem(
      'eventTimeLocale',
      eventTimeLocale ? eventTimeLocale : '',
    );
  }, [eventTimeLocale]);

  useEffect(() => {
    localStorage.setItem('timeZone', timeZone ? timeZone : '');
  }, [timeZone]);

  useEffect(() => {
    localStorage.setItem(
      'showSupporters',
      showSupporters ? showSupporters : '',
    );
  }, [showSupporters]);

  useEffect(() => {
    localStorage.setItem('eventLocation', eventLocation ? eventLocation : '');
  }, [eventLocation]);

  useEffect(() => {
    localStorage.setItem('nonProfitId', nonProfitV2Id ? nonProfitV2Id : '');
    setFieldTouched('nonProfitId', true, false);
  }, [nonProfitV2Id, setFieldTouched]);

  useEffect(() => {
    localStorage.setItem(
      'charityDonationGoal',
      charityDonationGoal ? charityDonationGoal : '',
    );
  }, [charityDonationGoal]);

  useEffect(() => {
    localStorage.setItem(
      'showDonationGoal',
      showDonationGoal ? showDonationGoal : '',
    );
  }, [showDonationGoal]);

  useEffect(() => {
    localStorage.setItem('charityQuote', charityQuote ? charityQuote : '');
  }, [charityQuote]);

  useEffect(() => {
    localStorage.setItem('lobMemo', lobMemo ? lobMemo : '');
  }, [lobMemo]);

  useEffect(() => {
    localStorage.setItem(
      'nonProfitEditId',
      nonProfitEditId ? nonProfitEditId : '',
    );
  }, [nonProfitEditId]);

  // /*  QUERIES */
  const { refetch: getCustomers } = useQuery(GET_CONTACTS_QUERY, {
    skip: true,
  });

  /* MUTATIONS */
  const [
    addressAutoComplete,
    { loading: mutationAddressAutoCompleteLoading },
  ] = useMutation(AddressAutoCompleteAddressMutation);

  const [
    googlePlacesZip,
    { loading: mutationGooglePlacesZipLoading },
  ] = useMutation(AddressFormGooglePlacesZipMutation);

  /* STATE */
  const [isAddressMenuOpen, setIsAddressMenuOpen] = useState(false);
  const [isHostNameMenuOpen, setIsHostNameMenuOpen] = useState(false);
  const [skipNonProfit, setSkipNonProfit] = useState(skipNpo === 'true');

  const handleToggleSetSkipNonProfit = () => {
    const newNpo = !skipNonProfit;
    setSkipNonProfit(newNpo);
    localStorage.setItem('skipNonProfit', newNpo ? 'true' : 'false');
  };

  const [selectAndModalState, setSelectAndModalState] = useState({
    isCustomerSelected: hostFullName !== '',
    isNewCustomerOpen: false,
  });
  const [addressPredictions, setAddressPredictions] = useState({});
  const [manualAddress, setManualAddress] = useState(
    getManualAddress(editing, 'manualAddress', event?.eventId),
  );
  const [customers, setCustomers] = useState([]);
  const [isImageUpdated, setIsImageUpdated] = useState(false);

  useEffect(() => {
    editing
      ? localStorage.setItem(
          `manualAddress${event?.eventId}`,
          manualAddress ? 'true' : 'false',
        )
      : localStorage.setItem(`manualAddress`, manualAddress ? 'true' : 'false');
  }, [editing, event?.eventId, manualAddress]);

  useEffect(() => {
    focusErrors({ errors, isSubmitting, manualAddress });
  }, [errors, isSubmitting, manualAddress]);

  /* FUNCTIONS */
  const change = (name: any, e: React.SyntheticEvent) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const handleSelectChange = (field: string, selectedItem: string) => {
    closeMenu(field);
    handleSetValue(field, selectedItem);
  };

  const handleSetValue = (field: string, selectedItem: string) => {
    if (field === 'eventDate') {
      setFieldValue('isDateUpdated', true);
    }
    setFieldValue(field, selectedItem);
    setFieldTouched(field, true, false);
  };

  const textChangeValid = (event: React.SyntheticEvent) => {
    return event.target.value.length <= 75;
  };

  const textChangeCharityQuoteValid = (
    name: string,
    event: React.SyntheticEvent,
  ) => {
    return name === 'charityQuote'
      ? event.target.value.length <= 600
      : event.target.value.length <= 40;
  };
  const {
    value: showStartAdornment,
    setTrue: setShowStartAdornmentTrue,
    setFalse: setShowStartAdornmentFalse,
  } = useToggle(!!charityDonationGoal);

  const handleCustomerSelected = ({
    firstName,
    lastName,
    fullName,
    organizationName,
    email,
    phone,
    accountId,
    contactId,
    dateOfBirth,
  }: CustomerData) => {
    handleSelectChange('hostContactId', contactId);
    handleSelectChange('hostFullName', organizationName || fullName);
    handleSelectChange('hostFirstName', firstName);
    handleSelectChange('hostLastName', lastName);
    handleSelectChange('hostEmail', email);
    handleSelectChange('hostPhone', phone);
    handleSelectChange('hostAccountId', accountId);
    handleSetValue('hostCustomerType', 'Existing Customer');
    handleSetValue('hostDateOfBirth', dateOfBirth);
    // if no account id is found, we need to pop open the
    // modal to gather the correct info
    const accountState = !accountId;
    setSelectAndModalState({
      isNewCustomerOpen: accountState,
      isCustomerSelected: true,
    });
  };
  const handleAddressSelected = async (selectedItem: any) => {
    const { addressLineOne, city, state, zip, placeId } = selectedItem;
    setFieldValue('addressLineOne', addressLineOne);
    setFieldValue('city', city);
    setFieldValue('state', state);
    setFieldValue('zip', zip);
    if (!zip) {
      const variables = {
        input: {
          placeId: placeId,
        },
      };
      return googlePlacesZip({ variables }).then(
        ({
          data: {
            googlePlacesZip: { zip },
          },
        }) => {
          setFieldValue('zip', zip || '');
          handleSelectChange(
            'displayAddress',
            formatAddress({ addressLineOne, city, state, zip }),
          );
        },
      );
    }
  };

  const handleOuterClick = (field: string) => {
    setFieldValue(field, '');
    closeMenu(field);
  };

  const closeMenu = (field: string) => {
    switch (field) {
      case 'displayAddress': {
        setIsAddressMenuOpen(false);
        break;
      }
      case 'hostFullName': {
        setIsHostNameMenuOpen(false);
        break;
      }
    }
  };

  const handleHostNameChange = async (
    event: React.ChangeEvent<{ value: string }>,
  ) => {
    event.preventDefault();
    change('hostFullName', event);
    const search = event.target.value;
    try {
      if (search && search !== '') {
        setIsHostNameMenuOpen(true);
        const results = await getCustomers({
          fullName: search,
        });
        if (get(results, 'data.viewer')) {
          setCustomers(formatCustomers(results.data));
        }
      } else {
        setIsHostNameMenuOpen(false);
        setCustomers([]);
      }
    } catch (error) {
      // continue
    }
  };

  const handleAddressChange = async (
    event: React.ChangeEvent<{ value: string }>,
  ) => {
    event.preventDefault();
    change('displayAddress', event);
    const searchTerm = event.target.value;
    if (!searchTerm) {
      handleSetValue('addressLineOne', '');
      handleSetValue('city', '');
      handleSetValue('state', '');
      handleSetValue('zip', '');
    }
    const variables = {
      input: {
        query: searchTerm,
      },
    };
    await addressAutoComplete({
      variables,
    }).then((res: any) => {
      const { data } = res;
      setIsAddressMenuOpen(true);
      const predictions =
        data &&
        data.addressAutoComplete &&
        data.addressAutoComplete.addressPredictions;
      let allowedAddresses;
      if (predictions) {
        allowedAddresses = predictions.filter((address: Address) => {
          return (
            allowedStates.includes(address.state) &&
            /\d/.test(address.addressLineOne)
          );
        });
      }
      const listData = allowedAddresses || [];
      const truthyList = listData.filter((address: Address) => {
        return !!address;
      }) as Address[];
      setAddressPredictions(truthyList);
    });
  };
  const handleHostReset = () => {
    setSelectAndModalState({
      isNewCustomerOpen: false,
      isCustomerSelected: false,
    });
    handleSetValue('hostContactId', '');
    handleSetValue('hostFullName', '');
    handleSetValue('hostFirstName', '');
    handleSetValue('hostLastName', '');
    handleSetValue('hostEmail', '');
    handleSetValue('hostPhone', '');
    handleSetValue('hostAccountId', '');
    handleSetValue('hostCustomerType', '');
    handleSetValue('hostDateOfBirth', '');
  };

  const onCancel = () => {
    setManualAddress(true);
    handleReset();
    return setIsNotEditing();
  };

  function handleNewCustomerClose() {
    handleHostReset();
  }

  function handleNewCustomerOpen() {
    setSelectAndModalState({
      ...selectAndModalState,
      isNewCustomerOpen: true,
    });
  }

  function add90Days(date: string) {
    const result = date
      ? DateTime.fromISO(date, { zone: timeZone })
      : currentDate;
    // Note: I guess we were adding 89 days so they have to clear the date selection in order to get the 90th day?
    // I'm changing it to 90 days and see if it presents any issues.
    return result.plus({ days: 90 });
  }
  function toggleAddManualAddress() {
    handleSelectChange(
      'addressLineOne',
      addressLineOne || displayAddress || '',
    );
    handleSelectChange('displayAddress', '');
    setManualAddress(!manualAddress);
  }

  const handleNonProfitV2Selected = ({
    nonProfitId,
    nonProfitEditId,
  }: {
    nonProfitId: string;
    nonProfitEditId?: string;
  }) => {
    setFieldValue('nonProfitId', nonProfitId);
    setFieldValue('nonProfitEditId', nonProfitEditId);
  };

  const toggleCheckbox = (
    name: any,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.persist();
    setFieldValue(name, e.target.checked?.toString());
    setFieldTouched(name, true, false);
  };
  const isWineTasting = eventType === EventTypeEnum.WineTasting;
  const isFundraiser = eventType === EventTypeEnum.Fundraiser;
  const disableNext =
    hostType === '' ||
    title === '' ||
    (isWineTasting && eventDate === '') ||
    (isWineTasting && trinityPartyType === 'EVENT' && addressLineOne === '');
  const isInPerson = trinityPartyType === 'EVENT';

  const isCustomer =
    hostType === EventHostingTypeEnum.Contact ||
    hostType === EventHostingTypeEnum.NonProfit;
  const isSelfHosted = hostType === EventHostingTypeEnum.Self;
  const isNotSelfHost = isCustomer;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const hasNonProfit = nonProfitV2Id && nonProfitV2Id !== '';
  const hasHostContactId = hostAccountId && hostAccountId !== '';
  const hasTitle = title && title !== '';
  const hasTrinityPartyType = trinityPartyType && trinityPartyType !== '';
  const ordersSubTotal = donation?.ordersSubTotal ?? 0;
  const qualified = ordersSubTotal > 500;
  const notAcceptingOrders =
    event?.trinityPartyStatus === EventStatusDictionary.CLOSED;
  const editable = editing
    ? event?.status === EventStatusDictionary.OPEN
    : true;
  const hostQuotePlaceholder =
    'Enter a message from your host that will appear on their event’s landing page. Ask them “Why you are raising funds for this cause?” A strong why yields stronger support.';
  const lobMemoPlaceholder = 'Write a quick note for your donation check';
  const showDonationGoalInput = showDonationGoal === 'true';
  const disableSave =
    (charityType === 'OHW-PARTNER' && !preApprovedNonProfit) ||
    (!qualified && isEmpty(touched));

  const handleRemoveEndDate = () => {
    handleSelectChange('eventDate', '');
    localStorage.setItem('eventDate', '');
    setShowSetEndDate();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={formContainerStyles}>
        {!editing && <Text variant="h3">Fundraiser Details</Text>}
        {!editing && (
          <>
            <ToggleLabel variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
              Will there be wine tasting?
            </ToggleLabel>
            <RadioButtonGroup
              id="eventType"
              label=""
              value={eventType}
              error={errors.eventType}
              touched={touched.eventType}
            >
              <FastField
                component={RadioChunkButton}
                name="eventType"
                id={EventTypeEnum.WineTasting}
                label="Yes"
                secondaryLabel={`Whether in-person or virtual, invite friends and family to sip wine
          and support the Host’s cause of choice with a <span style="font-weight: 500; font-style: italic">Wine Tasting Fundraiser</span>`}
              />
              <FastField
                component={RadioChunkButton}
                name="eventType"
                id={EventTypeEnum.Fundraiser}
                label="No"
                secondaryLabel={`Create an  <span style="font-weight: 500; font-style: italic">Online Fundraiser Page</span> for friends to shop and raise money for the Host’s cause of choice.`}
              />
            </RadioButtonGroup>
          </>
        )}
        {!!eventType && (
          <div>
            <ToggleLabel variant="custom" default={TEXT_DS.BODY_REGULAR_16}>
              Who is hosting?
            </ToggleLabel>
            <RadioButtonGroup
              id="hostTypeGroup"
              label=""
              value={hostType}
              error={errors.hostType}
              touched={touched.hostType}
            >
              <FastField
                component={RadioButton}
                name="hostType"
                id={EventHostingTypeEnum.Contact}
                label="A contact"
              />
              <FastField
                component={RadioButton}
                name="hostType"
                id={EventHostingTypeEnum.NonProfit}
                label="A nonprofit"
              />
              <FastField
                component={RadioButton}
                name="hostType"
                id={EventHostingTypeEnum.Self}
                label="I am hosting"
              />
            </RadioButtonGroup>
          </div>
        )}
        {!!isNotSelfHost && eventType && (
          <InputContainer>
            {!selectAndModalState.isCustomerSelected ||
            !!selectAndModalState.isNewCustomerOpen ? (
              <div>
                <Grid>
                  <Text className={genericLabelStyles}>
                    {hostType === EventHostingTypeEnum.NonProfit
                      ? 'Assign a contact for Host Reward points'
                      : `Contact's Name`}
                  </Text>
                  <AutoComplete
                    id="hostFullNameDropDown"
                    inputId="hostFullName"
                    placeholder="Search existing contacts"
                    value={values.hostFullName}
                    error={errors.hostFullName}
                    touched={touched.hostFullName}
                    isSubmitting={isSubmitting}
                    isMenuOpen={isHostNameMenuOpen}
                    handleCustomerSelected={handleCustomerSelected}
                    handleInputChange={handleHostNameChange}
                    handleOuterClick={handleOuterClick}
                    objectPredictions={customers}
                    handleNewCustomerOpen={handleNewCustomerOpen}
                    editing={editing}
                  />
                  <Grid item container>
                    <Grid
                      container
                      alignItems="center"
                      spacing={1}
                      justifyContent="center"
                    >
                      <HorizontalLineContainer item>
                        <HorizontalLine />
                      </HorizontalLineContainer>

                      <Grid item>
                        <OrText
                          variant="custom"
                          default={TEXT_DS.TITLE_UPPER_20}
                        >
                          OR
                        </OrText>
                      </Grid>
                      <HorizontalLineContainer item>
                        <HorizontalLine />
                      </HorizontalLineContainer>
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <NewCustomerButton>
                      <StyledAddNewButton
                        type="secondary"
                        fullWidth
                        disableRipple
                        onClick={handleNewCustomerOpen}
                      >
                        <CustomerTextContainer>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>
                              <Icons.AddCircleIcon />
                            </Grid>
                            <Grid item>ADD NEW CONTACT</Grid>
                          </Grid>
                        </CustomerTextContainer>
                      </StyledAddNewButton>
                    </NewCustomerButton>
                  </Grid>
                </Grid>
                <NewCustomerDialog
                  fullScreen={fullScreen}
                  disableEnforceFocus
                  open={selectAndModalState.isNewCustomerOpen}
                  onClose={handleNewCustomerClose}
                >
                  <AddCustomerForm
                    addCustomerButtonText="add new contact"
                    contactId={hostContactId}
                    isCustomerSelected={selectAndModalState.isCustomerSelected}
                    onClose={handleNewCustomerClose}
                    handleSelectedCustomer={handleCustomerSelected}
                    defaultValues={{
                      firstName: hostFirstName || '',
                      lastName: hostLastName || '',
                      email: hostEmail || '',
                      phone: hostPhone || '',
                      dateOfBirth: hostDateOfBirth || '',
                    }}
                  />
                </NewCustomerDialog>
              </div>
            ) : (
              <div>
                {/* TODO (monica): make this card a reusable component, similar one also used in AddCustomer */}
                <CustomerCard>
                  <CardInfo>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="flex-end"
                    >
                      <Grid item xs>
                        <CardTitle
                          variant="custom"
                          default={TEXT_DS.BODY_SEMIBOLD_18}
                        >
                          Contact Details
                        </CardTitle>
                        {hostFullName && (
                          <CardLine
                            variant="custom"
                            default={TEXT_DS.BODY_REGULAR_16}
                          >
                            {hostFullName}
                          </CardLine>
                        )}
                        {hostEmail && (
                          <CardLine
                            variant="custom"
                            default={TEXT_DS.BODY_REGULAR_16}
                          >
                            {hostEmail}
                          </CardLine>
                        )}
                        {hostPhone && hostPhone !== 'null' && (
                          <CardLine
                            variant="custom"
                            default={TEXT_DS.BODY_REGULAR_16}
                          >
                            {formatPhone(hostPhone)}
                          </CardLine>
                        )}
                      </Grid>
                      <Grid item>
                        <UpdateButton
                          variant="custom"
                          default={TEXT_DS.BODY_LINK_14}
                          onClick={handleHostReset}
                        >
                          Change
                        </UpdateButton>
                      </Grid>
                    </Grid>
                  </CardInfo>
                </CustomerCard>
              </div>
            )}
          </InputContainer>
        )}
        {!editing && (
          <>
            {((isSelfHosted && hostType && eventType) ||
              (!isSelfHosted && hasHostContactId && hostType && eventType)) && (
              <NonProfitContainer>
                <NonProfitSelection
                  nonProfitId={nonProfitV2Id}
                  oldNonProfit={event?.donationInfo?.nonProfit}
                  nonProfitEditId={nonProfitEditId}
                  handleNonProfitSelected={handleNonProfitV2Selected}
                />
                {!hasNonProfit && (
                  <Grid container alignItems="center">
                    <Checkbox
                      checked={skipNonProfit}
                      onClick={handleToggleSetSkipNonProfit}
                    />{' '}
                    <Text>Skip this step for now</Text>
                  </Grid>
                )}
              </NonProfitContainer>
            )}
          </>
        )}
        {(editing ||
          (hostType &&
            eventType &&
            isSelfHosted &&
            (hasNonProfit || skipNonProfit)) ||
          (!isSelfHosted &&
            hostType &&
            eventType &&
            (hasNonProfit || skipNonProfit) &&
            hasHostContactId)) && (
          <div>
            <EventTitleContainer>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Text
                  variant="custom"
                  default={TEXT_DS.BODY_REGULAR_16}
                  className={genericLabelStyles}
                >
                  Customize your event title
                </Text>
                <MuiToolTip
                  title={
                    "This title will be used on the fundraiser's landing page"
                  }
                  arrow
                  placement="top"
                  enterDelay={300}
                  leaveDelay={200}
                  className={toolTipStyles}
                  style={{
                    marginTop: -2,
                    marginRight: 8,
                  }}
                />
              </Grid>
              <MuiInputAutosize
                rows={1}
                rowsMax={4}
                maxCount="75"
                toolTipText="This title will be used on the fundraiser’s landing page"
                placeholder="E.g. Host’s name or Nonprofit’s Fundraiser"
                value={title}
                name="title"
                id="title"
                error={touched.title && Boolean(errors.title)}
                helperText={errors.title && touched.title && errors.title}
                validInput={title.length > 0}
                onChange={(e: React.SyntheticEvent) => {
                  if (textChangeValid(e)) {
                    change('title', e);
                  }
                }}
              />
            </EventTitleContainer>
            {isWineTasting && (
              <>
                {hasTitle && (
                  <Grid>
                    <ToggleLabel
                      variant="custom"
                      default={TEXT_DS.BODY_REGULAR_16}
                    >
                      Where is the event located?
                    </ToggleLabel>
                    <RadioButtonGroup
                      id="trinityPartyTypeGroup"
                      label=""
                      value={trinityPartyType}
                      error={errors.trinityPartyType}
                      touched={touched.trinityPartyType}
                    >
                      <FastField
                        component={RadioButton}
                        name="trinityPartyType"
                        id="EVENT"
                        label="In-person"
                      />
                      <FastField
                        component={RadioButton}
                        name="trinityPartyType"
                        id="EPARTY"
                        label="Virtual"
                      />
                    </RadioButtonGroup>
                  </Grid>
                )}
                {hasTitle && trinityPartyType && !isInPerson && (
                  <EventVirtualLinkContainer>
                    <Grid container>
                      <Grid item>
                        <Text
                          variant="custom"
                          default={TEXT_DS.BODY_REGULAR_16}
                          className={genericLabelStyles}
                        >
                          Add virtual event link (optional)
                        </Text>
                      </Grid>
                    </Grid>
                    <RegularInput
                      placeholder="Virtual event link"
                      value={eventVirtualLink}
                      name="eventVirtualLink"
                      id="eventVirtualLink"
                      fullWidth
                      hasError={
                        !isSubmitting &&
                        touched.eventVirtualLink &&
                        Boolean(errors.eventVirtualLink)
                      }
                      error={
                        touched.eventVirtualLink &&
                        Boolean(errors.eventVirtualLink)
                      }
                      helperText={
                        errors.eventVirtualLink &&
                        touched.eventVirtualLink &&
                        errors.eventVirtualLink
                      }
                      onChange={(e: React.SyntheticEvent) => {
                        change('eventVirtualLink', e);
                      }}
                    />
                  </EventVirtualLinkContainer>
                )}
                {hasTitle && isInPerson && trinityPartyType && (
                  <div>
                    {manualAddress ? (
                      <InputContainer>
                        <LongInput
                          label="Address 1"
                          placeholder="Enter address"
                          id="addressLineOne"
                          name="addressLineOne"
                          value={addressLineOne}
                          fullWidth
                          hasError={
                            !isSubmitting &&
                            touched.addressLineOne &&
                            Boolean(errors.addressLineOne)
                          }
                          error={
                            touched.addressLineOne &&
                            Boolean(errors.addressLineOne)
                          }
                          helperText={
                            errors.addressLineOne &&
                            touched.addressLineOne &&
                            errors.addressLineOne
                          }
                          hasSuccess={
                            editing
                              ? !isSubmitting &&
                                ((isEmpty(touched) && isEmpty(errors)) ||
                                  ((touched.addressLineOne ||
                                    !Boolean(touched.addressLineOne)) &&
                                    !Boolean(errors.addressLineOne)))
                              : !isSubmitting &&
                                touched.addressLineOne &&
                                !Boolean(errors.addressLineOne)
                          }
                          onChange={(e: React.SyntheticEvent) =>
                            change('addressLineOne', e)
                          }
                        />
                        <SearchLink onClick={toggleAddManualAddress}>
                          Back to address search
                        </SearchLink>
                        <LongInput
                          label="Address 2"
                          placeholder="Apt, Bldg, Ste #"
                          id="addressLineTwo"
                          name="addressLineTwo"
                          value={addressLineTwo}
                          fullWidth
                          hasError={
                            !isSubmitting &&
                            touched.addressLineTwo &&
                            Boolean(errors.addressLineTwo)
                          }
                          error={
                            touched.addressLineTwo &&
                            Boolean(errors.addressLineTwo)
                          }
                          helperText={
                            errors.addressLineTwo &&
                            touched.addressLineTwo &&
                            errors.addressLineTwo
                          }
                          hasSuccess={
                            editing
                              ? !isSubmitting &&
                                ((isEmpty(touched) && isEmpty(errors)) ||
                                  ((touched.addressLineTwo ||
                                    !Boolean(touched.addressLineTwo)) &&
                                    !Boolean(errors.addressLineTwo)))
                              : !isSubmitting &&
                                touched.addressLineTwo &&
                                !Boolean(errors.addressLineTwo)
                          }
                          onChange={(e: React.SyntheticEvent) =>
                            change('addressLineTwo', e)
                          }
                        />
                        <LongInput
                          label="City"
                          placeholder="Manhattan Beach"
                          id="city"
                          name="city"
                          value={city}
                          fullWidth
                          hasError={
                            !isSubmitting &&
                            touched.city &&
                            Boolean(errors.city)
                          }
                          error={touched.city && Boolean(errors.city)}
                          helperText={
                            errors.city && touched.city && errors.city
                          }
                          hasSuccess={
                            editing
                              ? !isSubmitting &&
                                ((isEmpty(touched) && isEmpty(errors)) ||
                                  ((touched.city || !Boolean(touched.city)) &&
                                    !Boolean(errors.city)))
                              : !isSubmitting &&
                                touched.city &&
                                !Boolean(errors.city)
                          }
                          onChange={(e: React.SyntheticEvent) =>
                            change('city', e)
                          }
                        />
                        <Row>
                          <Col>
                            <MiniSelect
                              IconComponent={ArrowDropDown}
                              input={
                                <SelectInput
                                  label="State"
                                  id="state"
                                  width="164px"
                                  hasError={
                                    touched.state && Boolean(errors.state)
                                  }
                                />
                              }
                              value={state}
                              displayEmpty
                              onChange={(e: React.SyntheticEvent) =>
                                change('state', e)
                              }
                              error={touched.state && Boolean(errors.state)}
                              helperText={
                                errors.state && touched.state && errors.state
                              }
                              inputProps={{
                                name: 'state',
                                id: 'state',
                                MenuProps: MenuProps,
                              }}
                            >
                              <MenuItem component="li" value="">
                                <DefaultOption>Select one</DefaultOption>
                              </MenuItem>
                              {statesAbbrev.map((state) => (
                                <MenuItem
                                  button={true}
                                  component="li"
                                  key={state}
                                  value={state}
                                >
                                  <OptionLabel>{state}</OptionLabel>
                                </MenuItem>
                              ))}
                            </MiniSelect>
                          </Col>
                          <Col>
                            <MedInput
                              label="Zipcode"
                              placeholder="90210"
                              id="zip"
                              name="zip"
                              value={zip}
                              fullWidth
                              hasError={
                                !isSubmitting &&
                                touched.zip &&
                                Boolean(errors.zip)
                              }
                              error={touched.zip && Boolean(errors.zip)}
                              helperText={
                                errors.zip && touched.zip && errors.zip
                              }
                              hasSuccess={
                                editing
                                  ? !isSubmitting &&
                                    ((isEmpty(touched) && isEmpty(errors)) ||
                                      ((touched.zip || !Boolean(touched.zip)) &&
                                        !Boolean(errors.zip)))
                                  : !isSubmitting &&
                                    touched.zip &&
                                    !Boolean(errors.zip)
                              }
                              onChange={(e: React.SyntheticEvent) =>
                                change('zip', e)
                              }
                            />
                          </Col>
                        </Row>
                      </InputContainer>
                    ) : (
                      <InputContainer>
                        <Grid>
                          <Text className={genericLabelStyles}>Location</Text>
                          <AutoComplete
                            id="displayAddressDropDown"
                            inputId="displayAddress"
                            placeholder="Start typing to search"
                            value={values.displayAddress}
                            error={errors.addressLineOne ? 'Required' : null}
                            touched={touched.displayAddress}
                            isSubmitting={isSubmitting}
                            isMenuOpen={isAddressMenuOpen}
                            handleAddressSelected={handleAddressSelected}
                            toggleAddManualAddress={toggleAddManualAddress}
                            handleInputChange={handleAddressChange}
                            handleOuterClick={handleOuterClick}
                            objectPredictions={addressPredictions}
                            editing={editing}
                          />
                        </Grid>
                        <Grid>
                          <Text className={genericLabelStyles}>
                            Apt, Bldg, Ste #
                          </Text>
                          <LongInput
                            placeholder="Apt, Bldg, Ste #"
                            id="addressLineTwo"
                            name="addressLineTwo"
                            value={addressLineTwo}
                            fullWidth
                            hasError={
                              !isSubmitting &&
                              touched.addressLineTwo &&
                              Boolean(errors.addressLineTwo)
                            }
                            error={
                              touched.addressLineTwo &&
                              Boolean(errors.addressLineTwo)
                            }
                            helperText={
                              errors.addressLineTwo &&
                              touched.addressLineTwo &&
                              errors.addressLineTwo
                            }
                            hasSuccess={
                              editing
                                ? !isSubmitting &&
                                  ((isEmpty(touched) && isEmpty(errors)) ||
                                    ((touched.addressLineTwo ||
                                      !Boolean(touched.addressLineTwo)) &&
                                      !Boolean(errors.addressLineTwo)))
                                : !isSubmitting &&
                                  touched.addressLineTwo &&
                                  !Boolean(errors.addressLineTwo)
                            }
                            onChange={(e: React.SyntheticEvent) =>
                              change('addressLineTwo', e)
                            }
                          />
                        </Grid>
                      </InputContainer>
                    )}
                    <LocationContainer>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Title>Show location on event page</Title>
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            disabled={false}
                            className={formControlStyles}
                            control={
                              <Switch
                                editing={false}
                                checked={eventLocation === 'true'}
                                onChange={(e: React.SyntheticEvent) =>
                                  toggleCheckbox('eventLocation', e)
                                }
                                value="eventLocation"
                              />
                            }
                          />
                        </Grid>
                      </Grid>
                    </LocationContainer>
                  </div>
                )}
              </>
            )}
            {hasTitle && ((trinityPartyType && isWineTasting) || isFundraiser) && (
              <Grid>
                <LogisticsContainer>
                  <Grid container direction="column" spacing={0}>
                    <Grid item>
                      {isFundraiser && (
                        <BoldText>
                          This fundraiser will be open 90 days. You can have it
                          close sooner by setting an end date, or manually
                          closing it later
                        </BoldText>
                      )}
                      {!editing && showSetEndDate && isFundraiser && (
                        <Box sx={{ pt: 1 }}>
                          <ButtonBase onClick={setHideSetEndDate}>
                            <Text
                              variant="custom"
                              style={{ color: theme.palette.mud.main }}
                              default={TEXT_DS.BODY_SEMIBOLD_16}
                            >
                              +Set an end date
                            </Text>
                          </ButtonBase>
                        </Box>
                      )}
                      {(isWineTasting || (isFundraiser && !showSetEndDate)) && (
                        <Grid>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Text
                              variant="custom"
                              default={TEXT_DS.BODY_REGULAR_16}
                              className={labelStyles}
                            >
                              {isFundraiser ? 'End date' : 'Event Date'}
                            </Text>
                            <MuiToolTip
                              title={
                                isFundraiser
                                  ? 'Once an order has been placed, the end date can only be changed one time.'
                                  : 'Once an order has been placed, the event date can only be changed one time.'
                              }
                              arrow
                              placement="top"
                              enterDelay={300}
                              leaveDelay={200}
                              className={toolTipStyles}
                              style={{
                                marginTop: 12,
                                marginRight: 8,
                              }}
                            />
                          </Grid>
                          <DatePickerContainer
                            disableToolbar
                            variant={isMobile ? 'dialog' : 'inline'}
                            fullWidth
                            disabled={!canUpdateEventDates}
                            id="eventDate"
                            name="eventDate"
                            placeholder="MM/DD/YYYY"
                            value={eventDate || null}
                            maxDate={
                              isFundraiser
                                ? add90Days(event?.eventDate || null)
                                : undefined
                            }
                            inputProps={{ className: dateStyles }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            minDate={currentDate}
                            autoOk={!isMobile}
                            DialogProps={{
                              PaperProps: {
                                className: datePickerPaperContainer,
                              },
                            }}
                            PopoverProps={{
                              PaperProps: {
                                className: datePickerPaperContainer,
                              },
                            }}
                            innerSidePadding={16}
                            onChange={(date) =>
                              handleSelectChange(
                                'eventDate',
                                formatDisplayDate(date),
                              )
                            }
                            error={
                              touched.eventDate && Boolean(errors.eventDate)
                            }
                            helperText={
                              errors.eventDate &&
                              touched.eventDate &&
                              errors.eventDate
                            }
                          />
                          {!editing &&
                            !showSetEndDate &&
                            eventDate &&
                            isFundraiser && (
                              <Box sx={{ pt: 1 }}>
                                <ButtonBase onClick={handleRemoveEndDate}>
                                  <Text
                                    variant="custom"
                                    style={{
                                      color: theme.palette.mud.main,
                                    }}
                                    default={TEXT_DS.BODY_SEMIBOLD_16}
                                  >
                                    Remove end date
                                  </Text>
                                </ButtonBase>
                              </Box>
                            )}
                          {isWineTasting && canUpdateEventDates && (
                            <Box sx={{ mt: 1 }}>
                              <Text
                                variant="custom"
                                default={TEXT_DS.BODY_SEMIBOLD_ITALIC_14}
                              >
                                {event?.version === EventVersionTypeEnum.v2
                                  ? 'Event will close 21 days after event date'
                                  : 'Event will close 8 days after event date'}
                              </Text>
                            </Box>
                          )}
                          {!canUpdateEventDates && (
                            <Box sx={{ mt: 1 }}>
                              <Text
                                variant="custom"
                                default={TEXT_DS.BODY_SEMIBOLD_ITALIC_14}
                              >
                                The date of this event can no longer be changed
                              </Text>
                            </Box>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <>
                    {isWineTasting && (
                      <>
                        <Grid item>
                          <Text
                            variant="custom"
                            default={TEXT_DS.BODY_REGULAR_16}
                            className={labelStyles}
                          >
                            Event Time
                          </Text>
                        </Grid>
                        <Row>
                          <Col>
                            <ShortInput
                              placeholder="06:00"
                              id="eventTime"
                              name="eventTime"
                              value={eventTime}
                              fullWidth
                              mask={TimeMask}
                              hasError={
                                !isSubmitting &&
                                touched.eventTime &&
                                Boolean(errors.eventTime)
                              }
                              error={
                                touched.eventTime && Boolean(errors.eventTime)
                              }
                              helperText={
                                errors.eventTime &&
                                touched.eventTime &&
                                errors.eventTime
                              }
                              hasSuccess={
                                editing
                                  ? !isSubmitting &&
                                    ((isEmpty(touched) && isEmpty(errors)) ||
                                      ((touched.eventTime ||
                                        !Boolean(touched.eventTime)) &&
                                        !Boolean(errors.eventTime)))
                                  : !isSubmitting &&
                                    touched.eventTime &&
                                    !Boolean(errors.eventTime)
                              }
                              onChange={(e: React.SyntheticEvent) =>
                                change('eventTime', e)
                              }
                            />
                          </Col>
                          <SelectContainer>
                            <Col>
                              <MiniSelect
                                IconComponent={ArrowDropDown}
                                input={
                                  <SelectInput
                                    id="eventTimeLocale"
                                    width={fullScreen ? '88px' : '96px'}
                                    hasError={
                                      touched.eventTimeLocale &&
                                      Boolean(errors.eventTimeLocale)
                                    }
                                  />
                                }
                                value={eventTimeLocale}
                                displayEmpty
                                onChange={(e: React.SyntheticEvent) =>
                                  change('eventTimeLocale', e)
                                }
                                helperText={
                                  errors.eventTimeLocale &&
                                  touched.eventTimeLocale &&
                                  errors.eventTimeLocale
                                }
                                error={
                                  touched.eventTimeLocale &&
                                  Boolean(errors.eventTimeLocale)
                                }
                                hasSuccess={
                                  editing
                                    ? !isSubmitting &&
                                      ((isEmpty(touched) && isEmpty(errors)) ||
                                        ((touched.eventTimeLocale ||
                                          !Boolean(touched.eventTimeLocale)) &&
                                          !Boolean(errors.eventTimeLocale)))
                                    : !isSubmitting &&
                                      touched.eventTimeLocale &&
                                      !Boolean(errors.eventTimeLocale)
                                }
                                inputProps={{
                                  name: 'eventTimeLocale',
                                  id: 'eventTimeLocale',
                                  MenuProps: MenuProps,
                                }}
                              >
                                {['PM', 'AM'].map((locale) => (
                                  <MenuItem
                                    button={true}
                                    component="li"
                                    key={locale}
                                    value={locale}
                                  >
                                    <OptionLabel>{locale}</OptionLabel>
                                  </MenuItem>
                                ))}
                              </MiniSelect>
                            </Col>
                            <Col>
                              <MiniSelect
                                IconComponent={ArrowDropDown}
                                input={
                                  <SelectInput
                                    id="timeZone"
                                    width={fullScreen ? '88px' : '96px'}
                                    hasError={
                                      touched.timeZone &&
                                      Boolean(errors.timeZone)
                                    }
                                  />
                                }
                                value={timeZone}
                                displayEmpty
                                onChange={(e: React.SyntheticEvent) =>
                                  change('timeZone', e)
                                }
                                helperText={
                                  errors.timeZone &&
                                  touched.timeZone &&
                                  errors.timeZone
                                }
                                error={
                                  touched.timeZone && Boolean(errors.timeZone)
                                }
                                hasSuccess={
                                  editing
                                    ? !isSubmitting &&
                                      ((isEmpty(touched) && isEmpty(errors)) ||
                                        ((touched.timeZone ||
                                          !Boolean(touched.timeZone)) &&
                                          !Boolean(errors.timeZone)))
                                    : !isSubmitting &&
                                      touched.timeZone &&
                                      !Boolean(errors.timeZone)
                                }
                                inputProps={{
                                  name: 'timeZone',
                                  id: 'timeZone',
                                  MenuProps: MenuProps,
                                }}
                              >
                                {timeZones.map((zone) => (
                                  <MenuItem
                                    button={true}
                                    component="li"
                                    key={zone.key}
                                    value={zone.value}
                                  >
                                    <OptionLabel>{zone.key}</OptionLabel>
                                  </MenuItem>
                                ))}
                              </MiniSelect>
                            </Col>
                          </SelectContainer>
                        </Row>
                      </>
                    )}
                  </>
                </LogisticsContainer>
                {!editing && (
                  <Details>
                    {!notAcceptingOrders && (
                      <div>
                        <DonationContainer>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Text
                                variant="custom"
                                default={TEXT_DS.BODY_SEMIBOLD_16}
                              >
                                Set a donation goal
                              </Text>
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                disabled={false}
                                className={formControlStyles}
                                control={
                                  <Switch
                                    checked={showDonationGoal === 'true'}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) => toggleCheckbox('showDonationGoal', e)}
                                    value="showDonationGoal"
                                  />
                                }
                              />
                            </Grid>
                          </Grid>
                          {showDonationGoalInput && (
                            <div>
                              <Text className={labelStyles}>
                                How much would you like to raise?
                              </Text>
                              <LongInput
                                placeholder={showStartAdornment ? '' : '$500'}
                                id="charityDonationGoal"
                                name="charityDonationGoal"
                                value={charityDonationGoal}
                                fullWidth
                                validate
                                mask={CurrencyMask}
                                onFocus={setShowStartAdornmentTrue}
                                onBlur={
                                  charityDonationGoal
                                    ? () => {}
                                    : setShowStartAdornmentFalse
                                }
                                startAdornment={showStartAdornment ? '$' : null}
                                hasError={
                                  !isSubmitting &&
                                  touched.charityDonationGoal &&
                                  Boolean(errors.charityDonationGoal)
                                }
                                hasSuccess={
                                  editing
                                    ? !isSubmitting &&
                                      ((isEmpty(touched) && isEmpty(errors)) ||
                                        !Boolean(errors.charityDonationGoal))
                                    : !isSubmitting &&
                                      !Boolean(errors.charityDonationGoal)
                                }
                                error={
                                  touched.charityDonationGoal &&
                                  Boolean(errors.charityDonationGoal)
                                }
                                helperText={
                                  errors.charityDonationGoal &&
                                  touched.charityDonationGoal &&
                                  errors.charityDonationGoal
                                }
                                onChange={(e: React.SyntheticEvent) =>
                                  change('charityDonationGoal', e)
                                }
                              />
                            </div>
                          )}
                        </DonationContainer>
                        <Grid>
                          <Text className={labelStyles}>
                            Message from your Host (optional){' '}
                          </Text>
                          <MuiInputAutosize
                            rows={6}
                            rowsMax={16}
                            maxCount="600"
                            placeholder={hostQuotePlaceholder}
                            value={charityQuote}
                            name="charityQuote"
                            id="charityQuote"
                            validInput={charityQuote.length > 0}
                            onChange={(e: React.SyntheticEvent) => {
                              if (
                                textChangeCharityQuoteValid('charityQuote', e)
                              ) {
                                change('charityQuote', e);
                              }
                            }}
                          />
                        </Grid>
                      </div>
                    )}
                    <Grid>
                      <Text className={labelStyles}>Check memo (optional)</Text>
                      <MuiInputAutosize
                        rows={1}
                        rowsMax={16}
                        maxCount="40"
                        placeholder={lobMemoPlaceholder}
                        value={lobMemo}
                        name="lobMemo"
                        id="lobMemo"
                        validInput={lobMemo.length > 0}
                        onChange={(e: React.SyntheticEvent) => {
                          if (textChangeCharityQuoteValid('lobMemo', e)) {
                            change('lobMemo', e);
                          }
                        }}
                      />
                    </Grid>
                  </Details>
                )}
                <Grid className={eventsSupportContainerStyles}>
                  <Title variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
                    Allow event page to show recent supporters
                  </Title>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item xs>
                      <Text
                        variant="custom"
                        default={TEXT_DS.BODY_REGULAR_14}
                        className={tinyTextStyles}
                      >
                        Event page to show which guests are supporting the
                        event's fundraiser through their purchases. Turn off to
                        hide this module if you do not want this information
                        visible.
                      </Text>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        disabled={false}
                        className={formControlStyles}
                        control={
                          <Switch
                            checked={showSupporters === 'true'}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => toggleCheckbox('showSupporters', e)}
                            value="showSupporters"
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
        )}
        {editing ? (
          <SaveButtons
            cancelButtonText="CANCEL"
            saveButtonText="SAVE"
            handleCancel={onCancel}
            mutationLoading={mutationLoading}
            handleSave={mutationLoading ? () => {} : handleSubmit}
            disableSave={isEmpty(touched) && !isImageUpdated}
          />
        ) : (
          <FooterButtons
            prevStepButtonText="CANCEL"
            nextStepButtonText="NEXT"
            handleNextStepChange={handleSubmit}
            handlePrevStepChange={handlePreviousStepChange}
            disableNext={disableNext}
          />
        )}
      </div>
    </form>
  );
};

export default memo(EventDetailsForm);
