import styled from '@emotion/styled';
import { Text, theme } from '@onehope/design-system-v2';

export const containerStyle = `
  font-family: ${theme.bodyFontFamily};
  color: ${theme.palette.primary.main};
  max-width: 460px;
  margin: 40px auto 40px auto;
  padding: 0 16px;
`;

export const Container = styled.div`
  ${containerStyle};
  display: flex;
  justify-content: center;
  height: 100vh;
`;

export const ContainerBlock = styled.div`
  ${containerStyle};
  display: block;
`;

export const TabContentContainer = styled.div`
  max-width: 460px;
  width: 100%;
  margin: auto;
  padding: 24px 16px 24px 16px;
  @media all and (min-width: 768px) {
    padding: 36px 0 16px 0;
  }
`;

export const BaseContainer = styled.div`
  position: relative;
  background-color: transparent;
  width: 100%;
  height: 100%;
  text-align: left;
  padding: 0;
  @media all and (min-width: 768px) {
  }
`;

export const DetailsWrapper = styled.div`
  margin-bottom: 120px;
`;

export const FieldContainer = styled.div`
  margin-bottom: 24px;
`;

export const DetailsContainer = styled.div`
  margin-bottom: 40px;
`;

export const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DetailsTitle = styled(Text)`
  && {
    margin-right: 12px;
  }
`;

export const NotificationText = styled(Text)`
  && {
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
  }
`;
export const EventOrdersWrapper = styled.div`
  margin-bottom: 8px;
`;

export const StraightLine = styled.div`
  max-width: 460px;
  height: 1px;
  background-color: ${theme.palette.primary.main};
  margin: 12px 0 16px 0;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 460px;
  //padding-top: 8px;
  padding-bottom: 8px;
  justify-content: space-between;
  @media all and (min-width: 768px) {
    justify-content: flex-start;
    text-align: left;
  }
`;

export const Key = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 19px;
  letter-spacing: normal;
  margin-right: 8px;
  text-align: left;
`;

export const Value = styled.div`
  font-size: 14px;
  line-height: 19px;
  word-break: break-word;
  margin-right: 0;
  text-align: right;
  @media all and (min-width: 768px) {
    max-width: 460px;
    width: 100%;
    text-align: left;
  }
`;

export const ToggleLabel = styled(Text)`
  && {
    margin: 24px 0 8px 0;
  }
`;

export const SummaryTitle = styled(Text)`
  && {
    font-weight: 500;
    text-align: left;
    max-width: 460px;
    margin: 8px 0;
  }
`;

export const SummaryValue = styled(Text)`
  && {
    word-break: break-word;
    max-width: 460px;
    margin-bottom: 16px;
    text-align: left;
  }
`;
