import React from 'react';
import Downshift from 'downshift';
import styled from '@emotion/styled';
import { MuiSearchInput } from '@onehope/design-system-ohw';
import { Icons, MenuItem, Paper, theme, Text } from '@onehope/design-system-v2';

import { renderSuggestion } from './autoCompleteUtils';

const { SearchIcon, AddCircleIcon, LocationOnIcon } = Icons;

const MenuContainer = styled(Paper)`
  max-height: 200px;
  overflow: auto;
  position: absolute;
  z-index: 100;
  padding-left: 0;
  max-width: calc(100% - 32px);
  width: 100%;
  border-radius: 0;
  @media only screen and (min-width: 432px) {
    max-width: ${props => (props.isWithinDialog ? '368px' : '460px')};
  }
`;

const AddMenuItem = styled(MenuItem)`
  && {
    height: ${props => `${props.height}px`};
  }
`;

const AddContainer = styled.div`
  width: 432px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const AddText = styled(Text)`
  width: 100%;
  height: 18px;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  font-family: ${theme.bodyFontFamily};
  color: ${theme.palette.primary.main};
`;

const Padding25 = styled.div`
  padding-bottom: 25px;
`;

interface CustomerData {
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  accountId: string;
  ownerAccountId: string;
}

interface AddressData {
  addressId: string;
  addressLineOne: string;
  addressLineTwo: string | null;
  city: string;
  country: string;
  id: string;
  placeId: string;
  state: string;
  zip: string | null;
}

interface AutoCompleteProps {
  value: string | undefined;
  error: any;
  touched: any;
  label: string;
  id: string;
  inputId: string;
  placeholder: string;
  isSubmitting: boolean;
  handleInputChange: (e: React.SyntheticEvent) => void;
  handleSelectChange?: (field: string, selectedItem: string) => void;
  handleCustomerSelected?: (selectedItem: CustomerData) => void;
  handleAddressSelected?: (selectedItem: any) => void;
  handleOuterClick: (field: string) => void;
  predictions?: string[];
  objectPredictions?: CustomerData[] | AddressData[] | NonProfitIRSNode[];
  isMenuOpen: boolean;
  validate: boolean;
  handleNewCustomerOpen?: any;
  toggleAddManualAddress?: any;
  toggleAddManualNonProfit?: any;
  editing: boolean;
  isWithinDialog?: boolean;
}

export default function AutoComplete(props: AutoCompleteProps) {
  const {
    value,
    error,
    touched,
    label,
    id,
    inputId,
    isWithinDialog,
    placeholder,
    isSubmitting,
    handleInputChange,
    handleSelectChange,
    handleCustomerSelected,
    handleAddressSelected,
    handleNewCustomerOpen,
    toggleAddManualAddress,
    toggleAddManualNonProfit,
    handleOuterClick,
    predictions,
    objectPredictions,
    isMenuOpen,
    editing,
  } = props;

  const changeQuery = (event: React.SyntheticEvent) => {
    handleInputChange(event);
  };
  const showAddCustomer =
    (id === 'hostFullNameDropDown' || id === 'nameDropDown') && value !== '';
  const showAddAddress =
    (id === 'eventAddressDropDown' || id === 'displayAddressDropDown') &&
    value !== '';
  const showAddNonProfit = id === 'customNonProfitDropDown' && value !== '';
  return (
    <Downshift
      id={id}
      initialSelectedItem={value}
      isOpen={isMenuOpen}
      onSelect={selectedItem => {
        if (handleCustomerSelected) {
          handleCustomerSelected(selectedItem);
        } else if (handleAddressSelected) {
          handleAddressSelected(selectedItem);
        } else if (handleSelectChange) {
          handleSelectChange(inputId, selectedItem);
        }
      }}
      onOuterClick={() => handleOuterClick(inputId)}
      itemToString={item => {
        return item == null
          ? ''
          : typeof item === 'object'
          ? item[Object.keys(item)[0]]
          : String(item);
      }}
    >
      {({
        getItemProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        selectedItem,
      }) => {
        return (
          <div>
            <MuiSearchInput
              {...getInputProps()}
              fullWidth
              variant="outlined"
              label={label}
              placeholder={placeholder}
              id={inputId}
              name={inputId}
              value={value}
              adornmentIcon={
                inputId === 'displayAddress' ||
                inputId === 'shippingAddress' ? (
                  <LocationOnIcon />
                ) : (
                  <SearchIcon />
                )
              }
              error={touched && Boolean(error)}
              helperText={error && touched && error}
              onChange={changeQuery}
            />
            <MenuContainer {...getMenuProps()} isWithinDialog={isWithinDialog}>
              {isMenuOpen && (
                <div>
                  {predictions &&
                    predictions.map((suggestion: string, index: number) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({
                          item: suggestion,
                        }),
                        highlightedIndex,
                        selectedItem,
                        id,
                      }),
                    )}

                  {objectPredictions && (
                    <div>
                      {Object.keys(objectPredictions).map(
                        (objSuggestion: string, index: number) =>
                          renderSuggestion({
                            objSuggestion: objectPredictions[index],
                            index,
                            itemProps: getItemProps({
                              item: objectPredictions[index],
                            }),
                            highlightedIndex,
                            selectedItem,
                            id,
                          }),
                      )}
                      {showAddCustomer && (
                        <AddMenuItem
                          height="46"
                          component="div"
                          onClick={() => handleNewCustomerOpen({ name: value })}
                        >
                          <AddContainer height="46">
                            <AddText>+ Add new customer</AddText>
                            <AddCircleIcon fontSize="small" />
                          </AddContainer>
                        </AddMenuItem>
                      )}
                      {showAddAddress && (
                        <AddMenuItem
                          height="56"
                          component="div"
                          onClick={toggleAddManualAddress}
                        >
                          <AddContainer height="56">
                            <AddText>+ Add address manually</AddText>
                            <AddCircleIcon fontSize="small" />
                          </AddContainer>
                        </AddMenuItem>
                      )}
                      {showAddNonProfit && (
                        <AddMenuItem
                          height="46"
                          component="div"
                          onClick={toggleAddManualNonProfit}
                        >
                          <AddContainer height="46">
                            <AddText>+ Add nonprofit manually</AddText>
                            <AddCircleIcon fontSize="small" />
                          </AddContainer>
                        </AddMenuItem>
                      )}
                    </div>
                  )}
                </div>
              )}
            </MenuContainer>
            <Padding25 />
          </div>
        );
      }}
    </Downshift>
  );
}
