import React, {
  SyntheticEvent,
  useState,
  ChangeEvent,
  useEffect,
  FormEvent,
} from 'react';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { FormikProps } from 'formik';
import Decimal from 'decimal.js';
import { useMutation } from '@apollo/react-hooks';

import { styles } from '@onehope/design-system';
import {
  Grid,
  styled,
  Checkbox,
  MenuItem,
  Typography,
  Text,
  TEXT_DS,
  Icons,
  ButtonV2,
  Spacer,
  Input,
} from '@onehope/design-system-v2';
const { CeBrownIcon, RefreshRightArrow, HeartOutline } = Icons;

import SummaryLine from '../SummaryLine';
import AddressAutoCompleteAddressMutation from '../../../../../mutations/Event/AddressFormAddressAutoCompleteMutation';
import AddressFormGooglePlacesZipMutation from '../../../../../mutations/Event/AddressFormGooglePlacesZipMutation';
import AutoComplete from '../../../../Events/EventDetailsForm/AutoComplete';
import { PhoneMask } from '../../../../Contacts/AddContactDialog/PhoneMask';
import { getFormattedDate } from '../../../../Contacts/AddContactDialog/helpers';
import { Container } from '../../../../Dialog/EventCreateDialog/FooterButtons';
import { Address } from '../../../../Events/EventDetailsForm';
import {
  MiniSelect,
  SelectInput,
  Label,
  DefaultOption,
  OptionLabel,
  ArrowDropDown,
  MenuProps,
} from '../../../../Events/EventDetailsForm/index.styles';
import { statesAbbrev } from '../../../../../utils/statesWeCanShipTo';
import { allowedStates } from '../../../../../../../../react-components/checkout/src/Shipment/utils/statesWeCanShipTo';
import {
  ContactType,
  useContactsContext,
} from '../../../../ContactsV3/ContactsContext';

import { ContactManagementTabs } from '../tabConstants';

import {
  FormWrapper,
  DetailsHeader,
  StraightLine,
  EditIcon,
} from '../tabContentStyles.styles';
import { MyFormValues } from './FormTypes';
import DateMask from '../../../../../utils/DateMask';

const {
  cssConstants: { textColor, disabledColor, font },
} = styles;

interface FormProps {
  errorNotification: string;
  setIsNotEditing: () => void;
  editing: boolean;
  toggleEditing: () => void;
  mutationLoading: boolean;
  contact: ContactType;
  handleTabChange: (event: FormEvent<EventTarget>, value: string) => void;
}

const ButtonContainer = styled(Container)`
  padding-bottom: 8px;
`;

export const SubTitle = styled('div')<{ editing: number }>`
  /* width: 96px; */
  height: 22px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: ${({ editing }) => (editing ? disabledColor : textColor)};
  margin-top: 17px;
  margin-bottom: 8px;
`;

export const StyledTypography = styled(Typography)<{
  disabled: boolean;
  fontSize?: number;
  lineHeight?: number;
}>`
  && {
    &.MuiTypography-body1 {
      font-family: ${font};
      font-size: ${({ fontSize }) => fontSize || 16}px;
      line-height: ${({ lineHeight }) => `${lineHeight}px` || 1.38};
      color: ${({ disabled }) => (disabled ? disabledColor : textColor)};
    }
  }
`;

export const CheckBoxIcon = styled(Checkbox)<{ editing: number }>`
  && {
    :hover,
    &.Mui-checked:hover {
      background-color: rgba(26, 26, 26, 0.1);
    }
    &.MuiCheckbox-colorPrimary,
    &.MuiCheckbox-colorSecondary {
      color: ${({ editing }) => (editing ? disabledColor : textColor)};
    }
  }
`;

const EditingWrapper = styled('div')<{ editing: boolean }>`
  opacity: ${({ editing }) => (editing ? 0.5 : 1)};
`;

const IconsWrapper = styled(Grid)({
  marginBottom: '4px',
  justifyContent: 'flex-end',
  flexWrap: 'nowrap',
  '@media all and (min-width: 768px)': {
    justifyContent: 'initial',
  },
});

const InputContainer = styled('div')({
  paddingBottom: '24px',
});

const SubscriberIconWrapper = styled(Grid)({
  justifyContent: 'flex-end',
  '@media all and (min-width: 768px)': {
    justifyContent: 'space-between',
  },
});

const IconText = styled(Grid)({
  gap: '8px',
  width: 'auto',
});

const IconLink = styled(Text)({
  marginLeft: '5px',
  cursor: 'pointer',
});

const TypeIcon = styled('span')({
  height: '20px',
  width: '20px',
  svg: {
    height: '20px',
    width: '20px',
  },
});

const ErrorNotification = styled('div')`
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  border-radius: 4px;
  border: solid 1px #b04a3d;
  background-color: #fdf6f5;
  padding: 14px 22px;
  margin-top: -16px;
  margin-bottom: 24px;
`;

export const ContactDetailsForm = ({
  values,
  editing,
  errors,
  errorNotification,
  touched,
  contact,
  handleReset,
  handleSubmit,
  isSubmitting,
  handleChange,
  setFieldValue,
  toggleEditing,
  mutationLoading,
  setFieldTouched,
  handleTabChange,
}: FormProps & FormikProps<MyFormValues>) => {
  const {
    contactId,
    accountId,
    isSubscriber,
    isHost,
    isCe,
    pointsExpireAt,
    selectedCause,
    totalCauseDonationAmount,
  } = contact;
  const pointExpirationDate = getFormattedDate(pointsExpireAt);

  const [isAddressMenuOpen, setIsAddressMenuOpen] = useState(false);
  const [addressPredictions, setAddressPredictions] = useState({});
  const {
    toggleSelectedContact,
    toggleModalState,
    toggleCEWarningState,
    hasPermissionToEdit,
    loadContact,
    togglePermissionState,
  } = useContactsContext();

  // fire off Wine Rep warning modal if isCe detected
  useEffect(() => {
    if (isCe) {
      toggleCEWarningState(true);
    }
  }, [isCe, toggleCEWarningState]);

  // Mutations
  const [addressAutoComplete] = useMutation(AddressAutoCompleteAddressMutation);

  const [googlePlacesZip] = useMutation(AddressFormGooglePlacesZipMutation);

  const {
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    dateOfBirth,
    lifetimeSpend,
    pointBalance,
    lastOrder,
    nextShipmentDate,
    addressLineOne,
    addressLineTwo,
    city,
    state,
    zip,
  } = values;

  const onCancel = () => {
    handleReset();
    toggleEditing();
  };

  const change = (name: any, e: SyntheticEvent) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const handleSetValue = (field: string, selecteditem: string) => {
    setFieldValue(field, selecteditem);
    setFieldTouched(field, true, false);
  };

  const handleArchiveContact = () => {
    toggleSelectedContact(contactId);
    toggleModalState(true);
  };

  const closeMenu = (field: string) => {
    switch (field) {
      case 'addressLineOne': {
        setIsAddressMenuOpen(false);
        break;
      }
    }
  };

  // handle a request to edit the Contact Details
  const handleEditContact = () => {
    const canEdit = hasPermissionToEdit(contact);
    if (!canEdit) {
      loadContact(contact);
      togglePermissionState(true);
    } else {
      toggleEditing();
    }
  };

  // Address Handling
  const shippingAddress =
    addressLineOne || addressLineTwo || city || state || zip;

  const handleAddressChange = async (event: ChangeEvent<{ value: string }>) => {
    event.preventDefault();
    change('addressLineOne', event);
    const searchTerm = event.target.value;
    const variables = {
      input: {
        query: searchTerm,
      },
    };
    await addressAutoComplete({
      variables,
    }).then((res: any) => {
      const { data } = res;
      setIsAddressMenuOpen(true);
      const predictions =
        data &&
        data.addressAutoComplete &&
        data.addressAutoComplete.addressPredictions;
      let allowedAddresses;
      if (predictions) {
        allowedAddresses = predictions.filter((address: Address) => {
          return (
            allowedStates.includes(address.state) &&
            /\d/.test(address.addressLineOne)
          );
        });
      }
      const listData = allowedAddresses || [];
      const truthyList = listData.filter((address: Address) => {
        return !!address;
      }) as Address[];
      setAddressPredictions(truthyList);
    });
  };

  const handleAddressSelected = async (selectedItem: any) => {
    const { addressLineOne, city, state, zip, placeId } = selectedItem;
    handleSetValue('addressLineOne', addressLineOne);
    handleSetValue('city', city);
    handleSetValue('state', state);
    closeMenu('addressLineOne');
    if (!zip) {
      const variables = {
        input: {
          placeId: placeId,
        },
      };
      return googlePlacesZip({ variables }).then(
        ({
          data: {
            googlePlacesZip: { zip },
          },
        }) => {
          handleSetValue('zip', zip || '');
        },
      );
    }
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <DetailsHeader>
        <Text variant="custom" default={TEXT_DS.BODY_SEMIBOLD_16}>
          {editing ? 'Edit personal details' : 'Personal Details'}
        </Text>
        {!editing && !isCe && (
          <EditIcon fontSize="small" onClick={() => handleEditContact()} />
        )}
      </DetailsHeader>
      <StraightLine isDark={true} />
      {editing ? (
        <>
          <InputContainer>
            <Input
              label={'First name'}
              placeholder="Jane"
              id="firstName"
              name="firstName"
              value={firstName}
              fullWidth
              hasError={
                !isSubmitting && touched.firstName && Boolean(errors.firstName)
              }
              hasSuccess={
                !isSubmitting &&
                ((isEmpty(touched) && isEmpty(errors)) ||
                  ((touched.firstName || !Boolean(touched.firstName)) &&
                    !Boolean(errors.firstName)))
              }
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={
                errors.firstName && touched.firstName && errors.firstName
              }
              onChange={(e: SyntheticEvent) => change('firstName', e)}
            />
          </InputContainer>
          <InputContainer>
            <Input
              label={'Last name'}
              placeholder="Austen"
              id="lastName"
              name="lastName"
              value={lastName}
              fullWidth
              hasError={
                !isSubmitting && touched.lastName && Boolean(errors.lastName)
              }
              hasSuccess={
                !isSubmitting &&
                ((isEmpty(touched) && isEmpty(errors)) ||
                  ((touched.lastName || !Boolean(touched.lastName)) &&
                    !Boolean(errors.lastName)))
              }
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={
                errors.lastName && touched.lastName && errors.lastName
              }
              onChange={(e: SyntheticEvent) => change('lastName', e)}
            />
          </InputContainer>
          <InputContainer>
            <Input
              label="Email address"
              placeholder="jane@gmail.com"
              id="emailAddress"
              name="emailAddress"
              value={emailAddress}
              fullWidth
              disabled={accountId ? true : false}
              hasError={
                !isSubmitting &&
                touched.emailAddress &&
                Boolean(errors.emailAddress)
              }
              hasSuccess={
                !isSubmitting &&
                touched.emailAddress &&
                !Boolean(errors.emailAddress)
              }
              error={touched.emailAddress && Boolean(errors.emailAddress)}
              helperText={
                errors.emailAddress &&
                touched.emailAddress &&
                errors.emailAddress
              }
              onChange={(e: SyntheticEvent) => change('emailAddress', e)}
            />
          </InputContainer>
          <InputContainer>
            <Input
              label={`Birthday${!!accountId ? '' : ' (optional)'}`}
              placeholder="MM / DD / YYYY"
              defaultChecked={false}
              id="dateOfBirth"
              name="dateOfBirth"
              fullWidth
              onChange={(e: React.SyntheticEvent) => change('dateOfBirth', e)}
              value={dateOfBirth}
              helperText={
                errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth
              }
              hasSuccess={
                !isSubmitting &&
                touched.dateOfBirth &&
                !Boolean(errors.dateOfBirth)
              }
              hasError={
                !isSubmitting &&
                touched.dateOfBirth &&
                Boolean(errors.dateOfBirth)
              }
              mask={DateMask}
            />
          </InputContainer>
          <InputContainer>
            <Input
              label="Mobile phone # (optional)"
              placeholder="(555) 123 - 4567"
              id="phoneNumber"
              name="phoneNumber"
              value={phoneNumber}
              fullWidth
              hasError={
                !isSubmitting &&
                touched.phoneNumber &&
                Boolean(errors.phoneNumber)
              }
              hasSuccess={
                !isSubmitting &&
                ((isEmpty(touched) && isEmpty(errors)) ||
                  ((touched.phoneNumber || !Boolean(touched.phoneNumber)) &&
                    !Boolean(errors.phoneNumber)))
              }
              mask={PhoneMask}
              error={touched.phoneNumber && Boolean(errors.phoneNumber)}
              helperText={
                errors.phoneNumber && touched.phoneNumber && errors.phoneNumber
              }
              onChange={(e: SyntheticEvent) => change('phoneNumber', e)}
            />
          </InputContainer>
          {!!accountId ? (
            <>
              <AutoComplete
                id="shippingAddressDropDown"
                inputId="addressLineOne"
                label="Default shipping address (optional)"
                placeholder="123 Madison ave"
                value={addressLineOne}
                error={errors.addressLineOne}
                touched={touched.addressLineOne}
                isSubmitting={isSubmitting}
                isWithinDialog={false}
                isMenuOpen={isAddressMenuOpen}
                handleAddressSelected={handleAddressSelected}
                handleInputChange={handleAddressChange}
                handleOuterClick={closeMenu}
                objectPredictions={addressPredictions}
              />
              <InputContainer>
                <Input
                  label="Address 2"
                  placeholder="Building, Suite, or Apt #"
                  id="addressLineTwo"
                  name="addressLineTwo"
                  value={addressLineTwo}
                  fullWidth
                  validate
                  hasError={
                    !isSubmitting &&
                    touched.addressLineTwo &&
                    Boolean(errors.addressLineTwo)
                  }
                  hasSuccess={
                    !isSubmitting &&
                    touched.addressLineTwo &&
                    !Boolean(errors.addressLineTwo)
                  }
                  error={
                    touched.addressLineTwo && Boolean(errors.addressLineTwo)
                  }
                  helperText={
                    errors.addressLineTwo &&
                    touched.addressLineTwo &&
                    errors.addressLineTwo
                  }
                  onChange={(e: React.SyntheticEvent) =>
                    change('addressLineTwo', e)
                  }
                />
              </InputContainer>
              <InputContainer>
                <Input
                  label="City"
                  placeholder="Los Angeles"
                  id="city"
                  name="city"
                  value={city}
                  width="264"
                  fullWidth
                  validate
                  hasError={
                    !isSubmitting && touched.city && Boolean(errors.city)
                  }
                  hasSuccess={
                    !isSubmitting && touched.city && !Boolean(errors.city)
                  }
                  error={touched.city && Boolean(errors.city)}
                  helperText={errors.city && touched.city && errors.city}
                  onChange={(e: React.SyntheticEvent) => change('city', e)}
                />
              </InputContainer>
              <InputContainer>
                <Label variant="custom" default={TEXT_DS.BODY_SEMIBOLD_12}>
                  State
                </Label>
                <MiniSelect
                  IconComponent={ArrowDropDown}
                  input={
                    <SelectInput
                      id="state"
                      width="100%"
                      hasError={touched.state && Boolean(errors.state)}
                    />
                  }
                  value={state}
                  displayEmpty
                  error={touched.state && Boolean(errors.state)}
                  helperText={errors.state && touched.state && errors.state}
                  onChange={(e: React.SyntheticEvent) => change('state', e)}
                  error={touched.state && Boolean(errors.state)}
                  inputProps={{
                    name: 'state',
                    id: 'state',
                    MenuProps: MenuProps,
                  }}
                >
                  <MenuItem component="li" value="">
                    <DefaultOption>Select one</DefaultOption>
                  </MenuItem>
                  {statesAbbrev.map((state) => (
                    <MenuItem
                      button={true}
                      component="li"
                      key={state}
                      value={state}
                    >
                      <OptionLabel>{state}</OptionLabel>
                    </MenuItem>
                  ))}
                </MiniSelect>
              </InputContainer>
              <InputContainer>
                <Input
                  label="Zipcode"
                  placeholder="90210"
                  id="zip"
                  name="zip"
                  value={zip}
                  width="146"
                  fullWidth
                  validate
                  hasError={!isSubmitting && touched.zip && Boolean(errors.zip)}
                  hasSuccess={
                    !isSubmitting && touched.zip && !Boolean(errors.zip)
                  }
                  error={touched.zip && Boolean(errors.zip)}
                  helperText={errors.zip && touched.zip && errors.zip}
                  onChange={(e: React.SyntheticEvent) => change('zip', e)}
                />
              </InputContainer>
            </>
          ) : null}
          {errorNotification && (
            <ErrorNotification>{errorNotification}</ErrorNotification>
          )}
          <ButtonContainer>
            <ButtonV2 type="secondary" fullWidth onClick={onCancel}>
              Cancel
            </ButtonV2>
            <ButtonV2
              fullWidth
              onClick={mutationLoading ? () => {} : handleSubmit}
            >
              Save
            </ButtonV2>
          </ButtonContainer>
        </>
      ) : (
        <>
          <SummaryLine title="Name" value={`${firstName} ${lastName}`} />
          <SummaryLine title="Email" value={emailAddress} />
          <SummaryLine title="Phone" value={phoneNumber} />
          <SummaryLine title="Birthday" value={dateOfBirth} />
          <SummaryLine
            title="Default shipping address"
            address={
              shippingAddress
                ? ({
                    addressLineOne,
                    addressLineTwo,
                    city,
                    state,
                    zip,
                  } as Address)
                : undefined
            }
          />
          <SummaryLine
            title="Type"
            value={
              <>
                {isCe && (
                  <IconsWrapper container>
                    <IconText container alignItems="center">
                      <TypeIcon>
                        <CeBrownIcon />
                      </TypeIcon>
                      Cause Entreprenuer
                    </IconText>
                  </IconsWrapper>
                )}
                {isHost && (
                  <IconsWrapper container>
                    <IconText container alignItems="center">
                      <TypeIcon>
                        <HeartOutline />
                      </TypeIcon>
                      Host
                    </IconText>
                  </IconsWrapper>
                )}
                {isSubscriber && (
                  <SubscriberIconWrapper container alignItems="center">
                    <IconText container alignItems="center">
                      <TypeIcon>
                        <RefreshRightArrow />
                      </TypeIcon>
                      Wine Club Subscriber
                    </IconText>
                    <IconLink
                      variant="custom"
                      default={TEXT_DS.BODY_LINK_14}
                      onClick={(e) =>
                        handleTabChange(e, ContactManagementTabs.WINECLUB)
                      }
                    >
                      Manage
                    </IconLink>
                  </SubscriberIconWrapper>
                )}
              </>
            }
          />
        </>
      )}
      <StraightLine />
      <EditingWrapper editing={editing ? true : false}>
        <SummaryLine
          title="Next Shipment"
          value={
            nextShipmentDate
              ? DateTime.fromISO(nextShipmentDate).toLocaleString(
                  DateTime.DATE_SHORT,
                )
              : '---'
          }
        />
        <SummaryLine
          title="Last Order"
          value={
            lastOrder
              ? DateTime.fromISO(lastOrder).toLocaleString(DateTime.DATE_SHORT)
              : '---'
          }
        />
        <SummaryLine
          title="Reward points balance"
          value={
            pointBalance
              ? `${pointBalance} ($${new Decimal(pointBalance)
                  .times(0.01)
                  .toFixed(2)})`
              : '0 ($0.00)'
          }
          pointBalance={pointBalance}
          pointExpirationDate={pointExpirationDate}
        />
        <SummaryLine
          title="Lifetime spend"
          value={lifetimeSpend ? `$${lifetimeSpend.toFixed(2)}` : '$0.00'}
        />
        <StraightLine />
        <SummaryLine
          title="Cause of choice"
          value={selectedCause?.nonProfit?.nonProfitName ?? '---'}
        />
        <SummaryLine
          title="$ raised for cause of choice"
          value={
            selectedCause?.donationTotal
              ? `$${selectedCause.donationTotal.toFixed(2)}`
              : '$0.00'
          }
        />
        <SummaryLine
          title="$ raised for all causes"
          value={
            totalCauseDonationAmount
              ? `$${totalCauseDonationAmount.toFixed(2)}`
              : '$0.00'
          }
        />
      </EditingWrapper>
      <Spacer lg="32px" sm="32px" xs="32px" />
      <ButtonV2
        fullWidth
        type="tertiary"
        onClick={() => handleArchiveContact()}
      >
        Delete Contact
      </ButtonV2>
    </FormWrapper>
  );
};

export default ContactDetailsForm;
