import React, { useState, useEffect, Fragment } from 'react';
import styled from '@emotion/styled';
import { styles as s } from '@onehope/design-system-ohw';
import { navigate } from 'gatsby';
import queryString from 'query-string';

import { MuiButton } from '@onehope/design-system-ohw';
import {
  Grid,
  Icons,
  useTheme,
  useMediaQuery,
  ButtonBase,
} from '@onehope/design-system-v2';

import TabFilter from './TabFilter';
import TablePagination from './TablePagination';
import TableListView from './TableListView';
import CardListView from './CardListView';
import Header from './Header';
import SearchBar from './SearchBar';
import TabNavigation from './TabNavigation/TabNavigation';
import OrderAddDialog from '../../Events/AddOrderDialog';
import EventCopyUrlDialog from '../../Dialog/EventCopyUrlDialog';

import {
  handleSortByTitle,
  handleSortByFullName,
  handleFilterByTab,
  handleSortByEventDate,
  handleSortByCloseDate,
  handleSearchAllEvents,
  handleSortBySubTotal,
  handleSortByStatus,
  handleSortByDonationAmount,
} from './helpers';
import {
  CEDash_EventsQuery_viewer_user_events_edges as EventsEdges,
  CEDash_EventsQuery_viewer_user as UserType,
} from '../../../queries/generatedTypes/CEDash_EventsQuery';
import { CEDash_CustomerDetailsPage_viewer_customer as ContactType } from '../../../queries/generatedTypes/CEDash_CustomerDetailsPage';
import NoEventsSVG from '../../../assets/noEvents';
import { useTrackingContextValue } from '../../../contexts/TrackingContext';
import { clearLocalStorage } from '../../Dialog/EventCreateDialog/ClearLocalStorage';
import { EventCreateSteps } from '../../Dialog/EventCreateDialog/stepperConstants';

const { cssConstants } = s;
const {
  AddCircleOutlinedIcon,
  AppsIcon: MuiAppsIcon,
  FormatListBulletedIcon: MuiFormatListBulletedIcon,
} = Icons;

const Container = styled.div`
  padding: 24px 16px;
  font-family: ${cssConstants.bodyFontFamily};
  a {
    color: ${cssConstants.primaryColor};
  }
  @media all and (min-width: 768px) {
    padding: 40px;
  }
`;

const AppsIcon = styled(MuiAppsIcon)`
  && {
    color: ${cssConstants.textColor};
  }
`;

const FormatListBulletedIcon = styled(MuiFormatListBulletedIcon)`
  && {
    margin-left: 8px;
    color: ${cssConstants.textColor};
  }
`;

const Toolbar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  @media all and (min-width: 960px) {
    flex-direction: row;
    padding: 0 0 40px 0;
  }
`;

const GridContainer = styled(Grid)`
  padding: 16px 0 0 0;
  @media all and (min-width: 960px) {
    padding: 0;
  }
`;

const ToggleView = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const NoEventsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px 16px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  @media all and (min-width: 768px) {
    padding: 40px 16px;
  }
`;

const NoEventsText = styled.div`
  color: ${cssConstants.textColor};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  padding: 24px 0;
`;

const NewEventButton = styled.div`
  max-width: 432px;
  width: 100%;
  height: 100px;
  color: ${cssConstants.primaryColor};
  cursor: pointer;
`;

const NewEventText = styled.div`
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 8px;
`;

const OrderTextContainer = styled.div`
  max-width: 432px;
  height: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface EventsTableProps {
  showHeader?: boolean;
  events: (EventsEdges | null)[] | null;
  contact?: ContactType;
  setEvents: React.Dispatch<
    React.SetStateAction<(EventsEdges | null)[] | null>
  >;
  allEvents: (EventsEdges | null)[] | null;
  user?: UserType;
  filterBy?: string;
}

export default function EventsTable(props: EventsTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showHeader, contact, events, setEvents, allEvents } = props;
  const { createEventStep } = queryString.parse(window.location.search);
  const { trackEventsV2 } = useTrackingContextValue();
  const [page, setPage] = useState(0);
  const [filterBy, setFilterBy] = useState(props.filterBy || 'open');
  const [tabValue, setTabValue] = useState(0);
  const [search, setSearch] = useState('');
  const [selectedEventId, setSelectedEventId] = useState('');
  const [isEventCreateOpen, setIsEventCreateOpen] = useState(!!createEventStep);
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
  const [isCopyUrlDialogOpen, setIsCopyUrlDialogOpen] = useState(false);
  const [nameSortDirection, setNameSortDirection] = useState(false);
  const [eventDateSortDirection, setEventDateSortDirection] = useState(false);
  const [closeDateSortDirection, setCloseDateSortDirection] = useState(false);
  const [subTotalSortDirection, setSubtotalSortDirection] = useState(false);
  const [donationAmountDirection, setDonationAmountSortDirection] = useState(
    false,
  );

  const [isTableView, setIsTableView] = useState(
    localStorage.getItem('isTableView')
      ? localStorage.getItem('isTableView') === 'true'
      : true,
  );

  const [
    donationStatusSortDirection,
    setDonationStatusSortDirection,
  ] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem('rowsPerPage')
      ? +localStorage.getItem('rowsPerPage')
      : 10,
  );

  useEffect(() => {
    setIsEventCreateOpen(!!createEventStep);
  }, [createEventStep]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const toggleView = () => {
    localStorage.setItem('isTableView', `${!isTableView}`);
    setIsTableView(!isTableView);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    localStorage.setItem('rowsPerPage', event.target.value);
    setPage(0);
  };

  const handleSortEvents = (sortBy: string) => {
    switch (sortBy) {
      case 'title':
        setEvents(handleSortByTitle(events, nameSortDirection));
        return setNameSortDirection(!nameSortDirection);
      case 'hostFullName':
        setEvents(handleSortByFullName(events, nameSortDirection));
        return setNameSortDirection(!nameSortDirection);
      case 'eventDate':
        setEvents(handleSortByEventDate(events, eventDateSortDirection));
        return setEventDateSortDirection(!eventDateSortDirection);
      case 'closeDate':
        setEvents(handleSortByCloseDate(events, closeDateSortDirection));
        return setCloseDateSortDirection(!closeDateSortDirection);
      case 'donationInfo.ordersSubTotal':
        setEvents(handleSortBySubTotal(events, subTotalSortDirection));
        return setSubtotalSortDirection(!subTotalSortDirection);
      case 'donationInfo.donationAmount':
        setEvents(handleSortByDonationAmount(events, donationAmountDirection));
        return setDonationAmountSortDirection(!donationAmountDirection);
      case 'donationInfo.status':
        setEvents(handleSortByStatus(events, donationStatusSortDirection));
        return setDonationStatusSortDirection(!donationStatusSortDirection);
      default:
        return setEvents(handleSortByFullName(events, nameSortDirection));
    }
  };

  const handleFilterByTabChange = (filterBy: string) => {
    setFilterBy(filterBy);
    setPage(0);
    /* on save tabs when filterBy prop is set */
    if (props.filterBy) {
      sessionStorage.setItem(`ceDashEventsAllTab`, JSON.stringify(filterBy));
    }
    return setEvents(handleFilterByTab(allEvents, filterBy, search));
  };

  const handleSearchEvents = (search: string) => {
    setSearch(search);
    setPage(0);
    return setEvents(handleSearchAllEvents(allEvents, filterBy, search));
  };

  const handleTabChange = (e: React.FormEvent<EventTarget>, value: number) => {
    e.preventDefault();
    setTabValue(value);
  };

  const handleSetSelectedEvent = (eventId: string | null | undefined) => {
    if (eventId) {
      setSelectedEventId(eventId);
    }
  };

  const handleOrderAdd = (eventId: string | null | undefined) => {
    if (eventId) {
      console.log('handle order add');
      setSelectedEventId(eventId);
      setIsOrderDialogOpen(!isOrderDialogOpen);
    }
  };

  const toggleEventCopyUrlDialog = (eventId: string | null | undefined) => {
    if (eventId) {
      setSelectedEventId(eventId);
      setIsCopyUrlDialogOpen(!isCopyUrlDialogOpen);
    }
  };

  const handleEventCreateClose = () => {
    navigate('/events');
    return setIsEventCreateOpen(false);
  };

  const handleEventCreateOpen = () => {
    clearLocalStorage();
    trackEventsV2({ eventName: 'Event Create Started' });
    navigate(`/events/?createEventStep=${EventCreateSteps.DETAILS}`);
    return setIsEventCreateOpen(true);
  };

  const eventCount = (events && events.length) || 0;
  const contactName = contact?.fullName || contact?.organizationName;
  const text = contactName
    ? `${contactName} does not have any associated events.`
    : 'You do not have any events.';
  return (
    <Fragment>
      <OrderAddDialog
        isOpen={isOrderDialogOpen}
        selectedEventId={selectedEventId}
        handleClose={setIsOrderDialogOpen}
      />
      <EventCopyUrlDialog
        isOpen={isCopyUrlDialogOpen}
        selectedEventId={selectedEventId}
        setIsCopyUrlDialogOpen={setIsCopyUrlDialogOpen}
      />
      {showHeader && (
        <>
          <Header
            user={props?.user}
            createEventStep={createEventStep}
            isEventCreateOpen={isEventCreateOpen}
            handleEventCreateOpen={handleEventCreateOpen}
            handleEventCreateClose={handleEventCreateClose}
          />
        </>
      )}
      {showHeader && (
        <TabNavigation tabValue={tabValue} handleChange={handleTabChange} />
      )}
      {allEvents && allEvents.length > 0 ? (
        <Fragment>
          <Container>
            <Toolbar>
              <SearchBar handleSearch={handleSearchEvents} />
              <GridContainer container alignItems="center" spacing={1}>
                <Grid item xs>
                  <TabFilter
                    filterBy={filterBy}
                    handleFilterByTabChange={handleFilterByTabChange}
                  />
                </Grid>
              </GridContainer>
              {!isMobile && (
                <ToggleView>
                  <ButtonBase onClick={toggleView}>
                    {isTableView ? <AppsIcon /> : <FormatListBulletedIcon />}
                  </ButtonBase>
                </ToggleView>
              )}
            </Toolbar>
            {isTableView && !isMobile ? (
              <TableListView
                page={page}
                events={events}
                contactId={contact?.accountId}
                rowsPerPage={rowsPerPage}
                handleOrderAdd={handleOrderAdd}
                setSelectedEventId={handleSetSelectedEvent}
                handleSortEvents={handleSortEvents}
                toggleEventCopyUrlDialog={toggleEventCopyUrlDialog}
              />
            ) : (
              <CardListView
                page={page}
                events={events}
                contactId={contact?.accountId}
                rowsPerPage={rowsPerPage}
                handleOrderAdd={handleOrderAdd}
                setSelectedEventId={handleSetSelectedEvent}
                toggleEventCopyUrlDialog={toggleEventCopyUrlDialog}
              />
            )}
            <TablePagination
              page={page}
              count={eventCount}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Container>
        </Fragment>
      ) : (
        <NoEventsContainer>
          <NoEventsSVG />
          <NoEventsText>{text}</NoEventsText>
          <NewEventButton>
            <MuiButton
              type="DASHED"
              fullWidth
              disableRipple
              onClick={handleEventCreateOpen}
            >
              <OrderTextContainer>
                <AddCircleOutlinedIcon />
                <NewEventText>add new event</NewEventText>
              </OrderTextContainer>
            </MuiButton>
          </NewEventButton>
        </NoEventsContainer>
      )}
    </Fragment>
  );
}
